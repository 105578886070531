import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Link from '../components/Common/Link'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import ImageSeo from '../assets/images/solutions/bim-paperless-construction/image-seo.png'

import * as s from '../pages/news.module.scss'

const BimPaperlessConstruction: React.FC = () => {
  const title = 'A paperless construction based on the BIM model'
  return (
    <>
      <SEO
        title={title}
        image={ImageSeo}
        description="The world is changing so rapidly that talking about paper at a construction site is no longer acceptable. Yes, they used to build on paper and built to last. But that was before. Now the speed is different. And Cloud-based Construction Software allows you to quickly implement Construction Project Control without paper documentation."
      />
      <NewsSectionBlock date="17.08.2023">
        <Typography variant="h1" color="blue">
          {title}
        </Typography>
        <div className="flex flex-col">
          <div className="flex items-center">
            <Typography variant="body2" className="mr-2">
              Nikita Pospelov
            </Typography>
            <Link
              href="https://www.linkedin.com/in/nikita-pospelov-bim"
              target="_blank"
              rel="nofollow noreferrer"
              className="flex has-text-primary"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 3.30667C0 2.53445 0.270278 1.89739 0.810811 1.39547C1.35134 0.893536 2.05406 0.642578 2.91892 0.642578C3.76835 0.642578 4.45559 0.889667 4.98069 1.38389C5.52123 1.89354 5.79151 2.55762 5.79151 3.37617C5.79151 4.11748 5.52897 4.73523 5.00386 5.22945C4.46333 5.7391 3.7529 5.99393 2.87259 5.99393H2.84942C1.99999 5.99393 1.31275 5.7391 0.787645 5.22945C0.262541 4.7198 0 4.07886 0 3.30667ZM0.301158 23.5769V8.10204H5.44402V23.5769H0.301158ZM8.29344 23.5769H13.4363V14.936C13.4363 14.3955 13.4981 13.9785 13.6216 13.685C13.8378 13.1599 14.166 12.7159 14.6062 12.353C15.0463 11.9901 15.5984 11.8086 16.2625 11.8086C17.9923 11.8086 18.8571 12.9746 18.8571 15.3067V23.5769H24V14.7044C24 12.4186 23.4595 10.685 22.3784 9.50358C21.2973 8.32211 19.8687 7.73138 18.0927 7.73138C16.1004 7.73138 14.5483 8.58852 13.4363 10.3028V10.3491H13.4131L13.4363 10.3028V8.10204H8.29344C8.32432 8.59624 8.33977 10.1329 8.33977 12.7121C8.33977 15.2912 8.32432 18.9128 8.29344 23.5769Z"
                  fill="#102538"
                />
              </svg>
            </Link>
          </div>
          <Typography variant="body2">
            Head of BIM Department at SIGNAX
          </Typography>
        </div>
        <Typography variant="body1">
          The world is changing so rapidly that talking about paper at a
          construction site is no longer acceptable. Yes, they used to build on
          paper and built to last. But that was before. Now the speed is
          different. And Cloud-based Construction Software allows you to quickly
          implement Construction Project Control without paper documentation.
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex justify-center w-100">
            <StaticImage
              width={330}
              src="../assets/images/solutions/bim-paperless-construction/image-1.png"
              alt="BIM project stages"
              title="BIM project stages"
              placeholder="blurred"
            />
            <StaticImage
              width={330}
              src="../assets/images/solutions/bim-paperless-construction/image-2.png"
              alt="BIM project stages"
              title="BIM project stages"
              placeholder="blurred"
            />
            <StaticImage
              width={330}
              src="../assets/images/solutions/bim-paperless-construction/image-3.png"
              alt="BIM project stages"
              title="BIM project stages"
              placeholder="blurred"
            />
          </div>
          <Typography variant="body2" color="gray">
            fig.1 BIM project stages
          </Typography>
        </div>
        <Typography variant="body1">
          Ultimately, the issue is not inherent in the paper but in the
          dispersed and irrelevant data accumulated within it. It is, after all,
          not always convenient to have Construction Documentation and a pen at
          hand on a construction site. Consequently, not all Construction Site
          Monitoring information is recorded and reaches decision-makers in a
          timely manner. So, what may one do to eliminate the “paper” problem?
        </Typography>
        <Typography variant="h2" color="blue">
          Construction Documentation of New Era
        </Typography>
        <Typography variant="body1">
          The solution to this problem should be considered if the company is
          not below the second level of Building Information Modeling (BIM)
          maturity. Namely, project teams are using a common data environment
          (CDE), and any combination of 3D and 2D CAD drawings is excluded.
          Naturally, to completely abandon paper at a construction site, it will
          be imperative to address numerous concerns still in the design stage,
          including synchronizing specifications in the model with other
          systems, establishing formalized workflows for coordinating data in
          the BIM model with related departments, especially with the Quantity
          take-off department; dispensing data to the construction site.
        </Typography>
        <Typography variant="body1">
          Under certain conditions, it is feasible to segment the transition to
          paperless construction into three distinct phases:
        </Typography>
        <ul className={cn(s.textLists)}>
          <li>
            <Typography variant="body1">
              <strong>1. 2D PDF drawings</strong>
            </Typography>
            <Typography variant="body1">
              All construction drawings are printed in electronic format (PDF)
              from the model without modification in third-party programs using
              two-dimensional tools.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>2. 2D views (projections)</strong>
            </Typography>
            <Typography variant="body1">
              The construction is performed using two-dimensional projections
              drawn up in the BIM model.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>3. BIM model</strong>
            </Typography>
            <Typography variant="body1">
              Augmented reality technology utilizing smartphones proves to be
              effective on a construction site, and the utilization of robotic
              total stations will enable the marking of a construction site in
              accordance with a model during installation and upon it. Next, the
              data from nature are loaded into a tablet, where an executive
              model of the structure is automatically built. This model is then
              compared with the BIM model. The installation's quality is
              checked, and the next steps can be adjusted if necessary. This
              eliminates the utilization of two-dimensional projections.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1">
          The drawback of the first and second stages is the risk of distortion,
          simplification, and partial loss of information when interpreting a
          three-dimensional prototype into a two-dimensional plane. On the
          contrary, it is undeniable that clicking on a structural element on a
          2D plan, switching to a 3D representation of the structural assembly,
          and observing it from various angles is a significant advantage of
          utilizing 3D Modeling in Construction. The way out is seen in using
          the BIM model at the construction site.
        </Typography>
        <Typography variant="body1">
          All stages require the organization of the CDE mentioned above and the
          use of Mobile Apps for Construction with the appropriate level of
          immersion. Let's take a closer look at them.
        </Typography>
        <Typography variant="body1">
          <strong>CDE</strong> is integral to Virtual Design and Construction
          (VDC). The primary directories for data in CDE must be established in
          the contract, considering the distribution of access rights according
          to the roles in construction and project management. Access to data
          for builders should be convenient, and information should be
          structured. In this situation, smartphones can be used to fix a change
          or defect in a couple of minutes, attach a photo, and let
          decision-makers know. The contract itself, along with other agreements
          and documentation, must be located in a CDE with access for all
          participants.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/bim-paperless-construction/image-4.png"
            alt="Autodesk Common Data Environment"
            title="Autodesk Common Data Environment"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            fig.2 Autodesk Common Data Environment
          </Typography>
        </div>
        <Typography variant="body1">
          So, what are the technical possibilities currently available for this?
        </Typography>
        <Typography variant="h2" color="blue">
          BIM software for construction
        </Typography>
        <Typography variant="body1">
          The necessary technologies for building without paper or with a
          minimum amount of it is already available today. If a complete
          transition to paperless format is not yet feasible, an alternative
          approach (1) may be to print the documentation (including PDF)
          directly from the information model in CDE, accompanied by a stamp
          (and, in certain instances, a watermark). The stamp must include
          information about the printing date, the name of the source model
          (fixed in the BIM Execution Plan, BEP), and the approval statuses of
          the main stakeholders (Project manager, BIM, and estimate department).
          Similar functionality is available in SIGNAX DOCS, which allows for
          the insertion of QR codes, stamps, and electronic digital signatures.
          Besides, SIGNAX TOOLS module helps to link and structure the as-built
          documentation with the model.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/bim-paperless-construction/image-5.png"
            alt="Issued for construction QR code"
            title="Issued for construction QR code"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            fig.3 Issued for construction QR code
          </Typography>
        </div>
        <Typography variant="body1">
          Nonetheless, utilizing direct projections from model (2) presents us
          with additional opportunities to centralize information with the aid
          of BIM software for construction. For example, as a component of the
          Construction Workflow Automation within the overall system, SIGNAX
          INSPECTION facilitates the monitoring of construction progress,
          enabling the submission of comments and confirmation of the
          elimination of defects. All of them can be accompanied by photos
          linked to drawings.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/bim-paperless-construction/image-6.png"
            alt="CDE issue management system"
            title="CDE issue management system"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            fig 4. CDE issue management system
          </Typography>
        </div>
        <Typography variant="body1">
          Therefore, to fully use the BIM model at the construction site, there
          must be all the tools needed to{' '}
          <strong>
            transfer data to the site, mark a construction according to a model
            during installation
          </strong>
          , and <strong>control the erected structure</strong>, as well as
          automated tools for the creation of as-built documentation (the
          executive model).
        </Typography>
        <Typography variant="body1">
          Moreover, the dissemination of data to the site the approaches may be
          different in each specific case: the formation of a separate
          construction model, the preliminary organization of the working model
          with related links in accordance with the stages of construction, and
          the issuance of the so-called grips. However, splitting the model
          (into floors, stair blocks, and others) may not always be convenient.
          With the SIGNAX TOOLS module, for example, it is possible to cut
          automatically working models into grips and work with the phased
          issuance of working documentation, which is especially important in
          parallel design and construction.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/bim-paperless-construction/image-7.png"
            alt="Construction stage BIM model"
            title="Construction stage BIM model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            fig.5 Construction stage BIM model
          </Typography>
        </div>
        <Typography variant="body1">
          It is worth noting that no ready-made solution on the market allows
          you to build structures using laser marking with a BIM model. The
          single tools that are currently available have yet to demonstrate
          their efficacy in practice, primarily due to the high expense of
          equipment.
        </Typography>
        <Typography variant="h2" color="blue">
          Digital construction is available today
        </Typography>
        <Typography variant="body1">
          All the conditions for the transition to paperless construction are
          already present. You can go through the indicated stages sequentially
          or skip the first one. However, it is evident that there will be no
          need for paper documentation on the construction site shortly. Thus,
          the designers will eliminate the routine design of 2D drawings. The
          builders will eliminate their ambiguous interpretation, and the
          construction industry will move to the next level of maturity.
        </Typography>
        <Typography variant="body1">
          The trends in the field are obvious. It is worth noting that more
          software for the construction industry has appeared and that there are
          more successfully implemented projects. Consequently, many specialists
          possessing valuable expertise in this domain have been associated with
          the market, thereby establishing novel benchmarks in the comprehensive
          construction management system based on the “paperless” BIM model. The
          products mentioned above have been created by such professionals to
          facilitate the thorny path of construction and are as close to the
          current needs as possible in the design and construction industry. In
          such circumstances, the emergence of a revolutionary technology for
          comprehensive construction based on the BIM model without the
          utilization of two-dimensional projections is imminent. When the
          company is comfortable with the second stage, then the transition to
          the third peak will be painless.
        </Typography>
        <hr className="w-1/3 has-background-grey-dark" />
        <Typography variant="body1" size={16}>
          <sup className="mr-1">1</sup>
          <Link
            href="https://www.thenbs.com/knowledge/what-is-the-pas-1192-framework"
            target="_blank"
            rel="nofollow noreferrer"
            className="has-text-primary"
          >
            International standard PAS 1192
          </Link>
          {` `}
          regulates the steps to reach the BIM level 2. “What is the PAS 1192
          framework?”, NBS, September 11, 2017,{` `}
          <Link
            href="https://www.thenbs.com/knowledge/what-is-the-pas-1192-framework"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
            style={{ wordBreak: 'break-word' }}
          >
            https://www.thenbs.com/knowledge/what-is-the-pas-1192-framework
          </Link>
        </Typography>
        <Typography variant="body1" size={16}>
          <sup className="mr-1">2</sup>
          “Common Data Environments”, NBS, April 5, 2019,{`\n`}
          <Link
            href="https://www.thenbs.com/knowledge/common-data-environments"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
            style={{ wordBreak: 'break-word' }}
          >
            https://www.thenbs.com/knowledge/common-data-environments
          </Link>
        </Typography>
        <Typography variant="body1" size={16}>
          <sup className="mr-1">3</sup>
          “What Is Virtual Design and Construction?”, Trimble Construction, May
          26, 2022,{'\n'}
          <Link
            href="https://constructible.trimble.com/construction-industry/what-is-virtual-design-and-construction"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
            style={{ wordBreak: 'break-word' }}
          >
            https://constructible.trimble.com/construction-industry/what-is-virtual-design-and-construction
          </Link>
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimPaperlessConstruction
